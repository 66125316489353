import * as React from 'react';

import {Loader} from '../loader';
import {TaskHelper} from '../../../../business/task_helper';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {Appraisal} from '../../../../models/appraisal';
import {Component} from '../../../component';
import {ReportRendererPresenter} from './report_renderer_presenter';
import {RenderingContextType} from '../../../../enum/rendering_context_type';

interface OwnProps {
    appraisal: Appraisal;
    renderingContext: RenderingContextType;
}

export const ReportRenderer: React.FC<OwnProps> = observer(function ReportRenderer(props) {
    const presenter = usePresenter(
        (container: Component) =>
            new ReportRendererPresenter(
                props.appraisal,
                props.renderingContext,
                container.business.conceptReportProvider(
                    new TaskHelper(container.network.ajaxDriver),
                    container.network.appraisalApi
                )
            )
    );

    return (
        <>
            <div className="concept-report-renderer">
                <div className="row">
                    <div className="col-12">
                        {presenter.initialConceptReport !== null ? (
                            <iframe
                                srcDoc={presenter.initialConceptReport}
                                ref={(iframe) => {
                                    if (iframe && iframe !== presenter.htmlIFrameElement) {
                                        iframe.onload = () => {
                                            presenter.onIframeRefupdate(iframe);
                                        };
                                    }
                                }}
                                frameBorder={0}
                                width="100%"
                                height="100%"
                            />
                        ) : (
                            <div className="col-12 text-center">
                                {presenter.hasError ? (
                                    <>
                                        <h2>Fout</h2>
                                        <h3>Er is wat mis gegaan met het genereren...</h3>
                                    </>
                                ) : (
                                    <>
                                        <Loader icon="c-icon c-icon-concept-report" addSpace />
                                        <h3>Concept rapport aan het genereren...</h3>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
});
