import {
    ApiReferenceSaleSetData,
    apiReferenceSaleSetDataToReferenceSaleSetData,
} from './models/api_reference_sale_set_data';
import {ApiReferenceSalesData, apiReferenceSalesDataToReferenceSalesData} from './models/api_reference_sales_data';
import {ApiSale, apiSaleToSale} from './models/api_sale';
import {ApiTaskReference, isApiTaskReference} from './models/api_task_reference';
import {
    ReferenceSalesData,
    ReferenceSaleSetData,
    ReferenceSaleSetRequestData,
} from '../business/reference_object_provider';

import {AjaxDriver} from '../../network/driver/ajax_driver';
import {Sale} from '../models/sale';
import {SetType} from '../models/reference_set/set_type';
import {TaskReference} from '../models/task_reference';
import {ReferenceSubscriptionType} from '../models/reference_subscriptions';
import {ReferenceSale} from '../appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale';
import {
    ApiReferenceSale,
    apiReferenceObjectToReferenceObject,
    apiReferenceSalesToReferenceSales,
} from './models/api_reference_sale';

export interface ReferenceObjectApi {
    getReferenceSales(appraisalId: number): Promise<ReferenceSalesData | TaskReference>;

    getReferenceSalesSet(data: ReferenceSaleSetRequestData): Promise<ReferenceSaleSetData>;

    getSale(appraisalId: number, id: string, setType: SetType): Promise<Sale | TaskReference>;

    getSaleByAddress(
        appraisalId: number,
        postalCode: string,
        houseNumber: string,
        letter: string,
        type: SetType,
        fetchSaleDetails: boolean
    ): Promise<Sale | TaskReference>;

    enhanceHighlightedReferenceSale(id: string, setData: ReferenceSaleSetRequestData): Promise<ReferenceSale>;

    getEnhancedReferenceSale(
        appraisalId: number,
        id: string,
        source: string | null,
        data: ReferenceSaleSetRequestData
    ): Promise<ReferenceSale | null>;

    indexPrice(appraisalId: number, price: number, postalCode: string, saleDate: string): Promise<number>;

    getSubscriptionPreferences<T>(appraisalId: number, subscriptionType: ReferenceSubscriptionType): Promise<T>;

    updateSubscriptionPreferences(
        appraisalId: number,
        subscriptionType: ReferenceSubscriptionType,
        preferences: unknown
    ): Promise<void>;

    getUserSubscriptionPreferences<T>(subscriptionType: ReferenceSubscriptionType): Promise<T>;

    searchReferenceObjects(
        postalCode: string,
        houseNumber: string,
        letter: string | null,
        subscriptionType: ReferenceSubscriptionType,
        preferences: object
    ): Promise<ReferenceSale[]>;
}

export class DefaultReferenceObjectApi implements ReferenceObjectApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public getReferenceSales(appraisalId: number): Promise<ReferenceSalesData | TaskReference> {
        return new Promise<ReferenceSalesData | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/reference-sales`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiReferenceSalesData | ApiTaskReference = await result.json();
                        if (!data) {
                            reject();
                        } else if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            if (!data.reference_objects) {
                                reject();
                            } else {
                                const referenceSaleData = apiReferenceSalesDataToReferenceSalesData(data);
                                resolve(referenceSaleData);
                            }
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getReferenceSalesSet(data: ReferenceSaleSetRequestData): Promise<ReferenceSaleSetData> {
        return new Promise<ReferenceSaleSetData>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${data.appraisalId}/reference-sales-set`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        reference_object_type: data.setType,
                        valuation_type_uuid: data.valuationTypeUuid,
                        build_year: data.buildYear,
                        living_area: data.livingArea,
                        plot_area: data.plotArea,
                        filters: data.filters
                            ? {
                                  address: data.filters.address,
                                  object_type: data.filters.objectType,
                                  ranges: data.filters.ranges
                                      ? {
                                            build_year: data.filters.ranges.buildYear,
                                            days_for_sale: data.filters.ranges.daysForSale,
                                            distance: data.filters.ranges.distance,
                                            plot_area: data.filters.ranges.plotArea,
                                            surface_area: data.filters.ranges.surfaceArea,
                                            asking_price: data.filters.ranges.askingPrice,
                                        }
                                      : null,
                              }
                            : null,
                        force_use_subscriptions: data.forceUseSubscriptions,
                    }),
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiReferenceSaleSetData = await result.json();
                        if (!data) {
                            reject();
                        } else {
                            if (!data.reference_objects) {
                                reject();
                            } else {
                                const referenceSaleSetData = apiReferenceSaleSetDataToReferenceSaleSetData(data);
                                resolve(referenceSaleSetData);
                            }
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getSale(appraisalId: number, id: string, setType: SetType): Promise<Sale | TaskReference> {
        const params = {
            reference_object_type: setType,
        };
        const query: string = Object.keys(params)
            .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k as keyof typeof params]))
            .join('&');

        return new Promise<Sale | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/sales/${id}?${query}`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiSale = await result.json();
                        if (!data) {
                            reject();
                        } else {
                            if (isApiTaskReference(data)) {
                                resolve({
                                    taskId: data.task_id,
                                });
                            } else {
                                resolve(apiSaleToSale(data));
                            }
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getSaleByAddress(
        appraisalId: number,
        postalCode: string,
        houseNumber: string,
        letter: string,
        type: SetType,
        fetchSaleDetails: boolean
    ): Promise<Sale | TaskReference> {
        const params = {
            postal_code: postalCode,
            house_number: houseNumber,
            letter: letter,
            reference_object_type: type,
            fetch_sale_details: fetchSaleDetails,
        };
        const query: string = Object.keys(params)
            .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k as keyof typeof params]))
            .join('&');

        return new Promise<Sale | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/sales/by-address?${query}`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiSale | ApiTaskReference = await result.json();
                        if (!data) {
                            reject();
                        } else {
                            if (isApiTaskReference(data)) {
                                resolve({
                                    taskId: data.task_id,
                                });
                            } else {
                                resolve(apiSaleToSale(data));
                            }
                        }
                        reject();
                    }
                    if (result.status === 400) {
                        const data: {error?: string} = await result.json();
                        if (data && data.error) {
                            reject(data.error);
                        }
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public enhanceHighlightedReferenceSale(id: string, data: ReferenceSaleSetRequestData): Promise<ReferenceSale> {
        return new Promise<ReferenceSale>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${data.appraisalId}/enhance-highlighted-reference-sale/${id}`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        reference_object_type: data.setType,
                        valuation_type_uuid: data.valuationTypeUuid,
                        build_year: data.buildYear,
                        living_area: data.livingArea,
                        plot_area: data.plotArea,
                        filters: data.filters
                            ? {
                                  address: data.filters.address,
                                  object_type: data.filters.objectType,
                                  ranges: data.filters.ranges
                                      ? {
                                            build_year: data.filters.ranges.buildYear,
                                            days_for_sale: data.filters.ranges.daysForSale,
                                            distance: data.filters.ranges.distance,
                                            plot_area: data.filters.ranges.plotArea,
                                            surface_area: data.filters.ranges.surfaceArea,
                                            asking_price: data.filters.ranges.askingPrice,
                                        }
                                      : null,
                              }
                            : null,
                        force_use_subscriptions: data.forceUseSubscriptions,
                    }),
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiReferenceSale = await result.json();
                        if (!data) {
                            reject();
                        } else {
                            const referenceSaleData = apiReferenceObjectToReferenceObject(data);
                            resolve(referenceSaleData);
                        }
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getEnhancedReferenceSale(
        appraisalId: number,
        id: string,
        source: string | null,
        data: ReferenceSaleSetRequestData
    ): Promise<ReferenceSale | null> {
        return new Promise<ReferenceSale | null>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/enhanced-reference-sale/${id}`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        reference_object_type: data.setType,
                        valuation_type_uuid: data.valuationTypeUuid,
                        build_year: data.buildYear,
                        living_area: data.livingArea,
                        plot_area: data.plotArea,
                        filters: data.filters
                            ? {
                                  address: data.filters.address,
                                  object_type: data.filters.objectType,
                                  ranges: data.filters.ranges
                                      ? {
                                            build_year: data.filters.ranges.buildYear,
                                            days_for_sale: data.filters.ranges.daysForSale,
                                            distance: data.filters.ranges.distance,
                                            plot_area: data.filters.ranges.plotArea,
                                            surface_area: data.filters.ranges.surfaceArea,
                                            asking_price: data.filters.ranges.askingPrice,
                                        }
                                      : null,
                              }
                            : null,
                        force_use_subscriptions: data.forceUseSubscriptions,
                        source: source,
                    }),
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: ApiReferenceSale = await result.json();
                        if (!data) {
                            reject();
                        } else {
                            const referenceSaleData = apiReferenceObjectToReferenceObject(data);
                            resolve(referenceSaleData);
                        }
                    } else if (result.status === 404) {
                        resolve(null);
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public indexPrice(appraisalId: number, price: number, postalCode: string, saleDate: string): Promise<number> {
        return new Promise<number>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/sales/index-price`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        postal_code: postalCode,
                        sale_date: saleDate,
                        price: price,
                    }),
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: {
                            indexed_price: number;
                        } = await result.json();
                        if (!data) {
                            reject();
                        } else {
                            resolve(data.indexed_price);
                            return;
                        }
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getSubscriptionPreferences<T>(appraisalId: number, subscriptionType: ReferenceSubscriptionType): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/reference-sales/preferences/${subscriptionType}`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(await result.json());
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public updateSubscriptionPreferences(
        appraisalId: number,
        subscriptionType: ReferenceSubscriptionType,
        preferences: unknown
    ): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/reference-sales/preferences/${subscriptionType}`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(preferences),
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public getUserSubscriptionPreferences<T>(subscriptionType: ReferenceSubscriptionType): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/reference-objects/preferences/${subscriptionType}`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(await result.json());
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public searchReferenceObjects(
        postalCode: string,
        houseNumber: string,
        letter: string,
        subscriptionType: ReferenceSubscriptionType,
        preferences: object
    ): Promise<ReferenceSale[]> {
        return new Promise<ReferenceSale[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/reference-objects/search/${subscriptionType}`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: {
                        'X-Csrf-Token': (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)
                            .content,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ...preferences,
                        postal_code: postalCode,
                        house_number: houseNumber,
                        letter: letter,
                    }),
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(apiReferenceSalesToReferenceSales(await result.json()));
                        return;
                    } else if (result.status === 400) {
                        const data: {reason?: string} = await result.json();
                        if (data && data.reason) {
                            reject(new ReferenceObjectsError(data.reason, data));
                            return;
                        }
                    }

                    reject(result);
                })
                .catch((error) => reject(error));
        });
    }
}

export class ReferenceObjectsError<T = unknown> extends Error {
    public constructor(message: string, public readonly data: T) {
        super(message);
        this.name = 'ReferenceObjectsError';
    }
}
