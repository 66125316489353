$('.btn-copy').each((_, el) => {
    const input = document.getElementById(el.attributes['data-copy-input']?.value);
    if (input) {
        el.addEventListener('click', () => {
            input.select();
            input.setSelectionRange(0, 99999);

            navigator.clipboard.writeText(input.value);
        });
    }
});
