import * as React from 'react';

import {FlashMessageBroadcaster, Type} from '../../../../../business/flash_message_broadcaster';
import {SimpleQuestionPresenter, SimpleQuestionPresenterProps} from '../simple/simple_question_presenter';
import {action, computed, makeObservable, observable, override, runInAction} from 'mobx';

import {Answer} from '../../../../../models/answer';
import {AnswerInteractor} from '../../../../../business/answering/answer_interactor';
import {AttachmentUploadInteractor} from '../../../../../business/attachments/attachment_upload_interactor';
import {BlobCacheInteractor} from '../../../../../business/attachments/blob_cache_interactor';
import {CompositeSubscription} from '../../../../../../support/composite_subscription';
import {isSet} from '../../../../../../support/is_set';
import {mergeMap} from 'rxjs/operators';
import {parseImageAnswerContents} from '../../../../../business/support/parse_image_answer_contents';
import {AttachmentQuestionsInteractor} from '../../../../../business/attachment_questions_interactor';
import {GlobalProvider} from '../../../../../../business/global_provider';
import {UserType} from '../../../../../enum/user_type';
import {AnswerTouchState} from '../../../../../enum/answer_touch_state';
import {AttachmentQuestionAvatarData} from './attachment_question';
import {UserRole} from '../../../../../enum/user_role';
import {classNames} from '../../../../../../support/classnames';
import {getFileName} from '../../../../../../support/file_name';
import {AutomatorInteractor} from '../../../../../business/automator_interactor';
import {Question} from '../../../../../models/question';
import {TechnicalReference} from '../../../../../enum/technical_reference';

export interface AttachmentQuestionProps extends SimpleQuestionPresenterProps {
    fileTypes: string[];
}

export enum ImageUploadState {
    SYNCED = 'synced',
    SYNCING = 'syncing',
    UNSYNCED = 'unsynced',
    FAILED = 'failed',
    VALIDATION_INSTITUTE_UPLOAD_FAILED = 'validation-institute-upload-failed',
}

export interface AttachmentProps {
    path: string | null;
    state: ImageUploadState;
    name: string;
    type: string;
    exif?: Record<string, unknown>;
}

export class AttachmentQuestionPresenter extends SimpleQuestionPresenter {
    @observable public displayIcon = false;
    @observable public hasImageUrl = false;
    @observable.ref protected file: File | null = null;
    @observable public uploadProgress: number | null = null;
    @observable public automatorModalShown = false;
    @observable private automatorPoll: Promise<void> | null = null;

    public showInReportQuestion: Question | null = null;
    @observable.ref private showInReportAnswer: Answer | null = null;

    protected objectUrl: string | null = null;

    @computed
    public get showRetryButton() {
        if (this.loading) {
            return false;
        }

        if (isSet(this.answer) && isSet(this.answer.contents)) {
            const contents = parseImageAnswerContents(this.answer.contents);
            if (
                contents.state === ImageUploadState.FAILED ||
                contents.state === ImageUploadState.VALIDATION_INSTITUTE_UPLOAD_FAILED
            ) {
                return true;
            }
        }

        return false;
    }

    @computed
    public get showError() {
        return this.showRetryButton && this.file === null;
    }

    @override
    public override get loading() {
        if (!isSet(this.answer)) {
            return true;
        }

        if (!isSet(this.answer.contents)) {
            if (this.automatorPoll !== null) {
                return true;
            }

            return false;
        }

        const contents = parseImageAnswerContents(this.answer.contents);
        return contents.state === ImageUploadState.UNSYNCED || contents.state === ImageUploadState.SYNCING;
    }

    @override
    public override get isDisabled(): boolean {
        if (!super.isDisabled) {
            return false;
        }

        if (isSet(this.answer) && isSet(this.answer.contents)) {
            const contents = parseImageAnswerContents(this.answer.contents);
            if (contents.state === ImageUploadState.SYNCING || contents.state === ImageUploadState.UNSYNCED) {
                return false;
            }
        }

        return true;
    }

    @computed
    public get empty() {
        return !isSet(this.answer) || !isSet(this.answer.contents);
    }

    @computed
    public get filename(): string | null {
        if (this.file !== null && this.file.name !== null && this.answer !== undefined && this.answer.changed) {
            return getFileName(this.file);
        }
        if (this.answer !== undefined && this.answer.file !== null && this.answer.file.originalFilename) {
            return this.answer.file.originalFilename;
        }

        return null;
    }

    @computed
    public get url(): string | null {
        if (this.answer !== undefined && this.answer.file !== null) {
            return this.answer.file.url;
        }
        if (this.objectUrl === null && this.file !== null && this.file.size > 0) {
            this.createObjectUrl();
        }
        return this.objectUrl;
    }

    @computed
    public get uncompressedUrl(): string | null {
        if (this.answer !== undefined && this.answer.file !== null) {
            return this.answer.file.uncompressedUrl;
        }
        if (this.objectUrl === null && this.file !== null && this.file.size > 0) {
            this.createObjectUrl();
        }
        return this.objectUrl;
    }

    @computed
    public get canControlShowInReport(): boolean {
        const userType = this.globalProvider.global?.userType;
        return (
            this.showInReportAnswer !== null &&
            this.url !== null &&
            userType !== null &&
            [UserType.APPRAISER, UserType.EMPLOYEE, UserType.JUNIOR_APPRAISER].includes(userType)
        );
    }

    @computed
    public get showInReport(): boolean {
        return (
            this.showInReportAnswer === null ||
            this.showInReportAnswer.contents === null ||
            this.showInReportAnswer.contents !== '0'
        );
    }

    @action
    protected createObjectUrl() {
        if (this.file !== null) {
            this.objectUrl = URL.createObjectURL(this.file);
        }
    }

    @computed
    public get isImage(): boolean {
        if (this.filename === null) {
            return false;
        }
        const parts = this.filename.split('.');
        return ['jpg', 'png', 'bmp'].indexOf(parts[parts.length - 1].toLowerCase()) !== -1;
    }

    @computed
    public get triggerableAutomatorGroup() {
        if (!this.question.automationReference) {
            return null;
        }

        return (
            this.globalProvider.global.triggerableAutomatorGroups?.find((group) =>
                group.references.includes(this.question.automationReference as string)
            ) ?? null
        );
    }

    public get isAttachmentsChecked(): boolean {
        return this.globalProvider.global.attachmentsCheckedBy !== null;
    }

    public get attachmentsCheckedBy(): string | null {
        return this.globalProvider.global.attachmentsCheckedBy;
    }

    public get hasUserAvatar() {
        return this.answer && this.answer.file !== null;
    }

    public get avatarData(): AttachmentQuestionAvatarData {
        if (this.answer) {
            if (this.answer.filledByAutomator || !this.answer.createdByUserType || !this.answer.createdByUserRole) {
                // Icon for taXapi AI
                return {
                    iconClass: classNames('c-icon-system', {
                        'list-avatar-active': this.isAttachmentsChecked,
                    }),
                    tooltip: this.isAttachmentsChecked
                        ? 'Deze bijlage is gecontroleerd door ' + (this.attachmentsCheckedBy ?? 'taXapi')
                        : 'Deze bijlage is toegevoegd door taXapi AI',
                    initials: null,
                };
            } else if (
                this.answer.createdByUserRole !== UserRole.USER ||
                [UserType.APPRAISER, UserType.EMPLOYEE, UserType.JUNIOR_APPRAISER].includes(
                    this.answer.createdByUserType
                )
            ) {
                // Icon for taXapi admin users, appraisers and employees
                return {
                    iconClass: 'list-avatar-primary-color',
                    tooltip: 'Deze bijlage is toegevoegd door ' + this.answer.createdByUserName,
                    initials: this.answer.createdByUserInitials ?? null,
                };
            } else if (this.answer.createdByUserName) {
                //  Icon for normal users (e.g. client)
                return {
                    iconClass: 'list-avatar-gray ion-md-person',
                    tooltip: 'Deze bijlage is toegevoegd door ' + this.answer.createdByUserName,
                    initials: null,
                };
            }
        }

        // Fallback
        return {
            iconClass: 'list-avatar-gray ion-md-person',
            tooltip: 'Deze bijlage is toegevoegd door de opdrachtgever',
            initials: null,
        };
    }

    public get shouldShowUnreadIndicator() {
        const userType = this.globalProvider.global?.userType;
        return (
            userType !== null && [UserType.APPRAISER, UserType.EMPLOYEE, UserType.JUNIOR_APPRAISER].includes(userType)
        );
    }

    public get isActive() {
        return this.answer && this.answer.file !== null;
    }

    protected _subscriptions = new CompositeSubscription();

    constructor(
        protected fileTypes: string[],
        protected globalProvider: GlobalProvider,
        protected blobCacheInteractor: BlobCacheInteractor,
        protected attachmentUploadInteractor: AttachmentUploadInteractor,
        protected answerInteractor: AnswerInteractor,
        protected attachmentQuestionsInteractor: AttachmentQuestionsInteractor,
        protected flashMessageBroadcaster: FlashMessageBroadcaster,
        protected automatorInteractor: AutomatorInteractor,
        ...simpleQuestionPresenterParameters: ConstructorParameters<typeof SimpleQuestionPresenter>
    ) {
        super(...simpleQuestionPresenterParameters);
        makeObservable(this);

        this.showInReportQuestion =
            this.questionSet
                .findChildQuestionsByParentUuid(this.question.uuid)
                .find((q) => q.technicalReference === TechnicalReference.ATTACHMENT_IN_REPORT) ?? null;
    }

    public mount(): void {
        super.mount();

        this._subscriptions.add(
            this.answerController
                .answerByIdentifiersStream(this.question.uuid, this.parentAnswerUuid ?? null, this.iteration ?? null)
                .pipe(mergeMap((answer) => this.blobCacheInteractor.find(answer.uuid)))
                .subscribe((file) => {
                    runInAction(() => {
                        this.file = file;
                    });
                })
        );

        const group = this.triggerableAutomatorGroup;
        if (group && this.automatorInteractor.isLoading(group.group)) {
            this.pollAutomatorPromise(this.automatorInteractor.poll(group.group));
        }

        if (this.showInReportQuestion !== null) {
            const questionUuid = this.showInReportQuestion.uuid;

            this._subscriptions.add(
                this.answerController
                    .answerByIdentifiersStream(
                        this.question.uuid,
                        this.parentAnswerUuid ?? null,
                        this.iteration ?? null
                    )
                    .pipe(
                        mergeMap((answer) =>
                            this.answerController.answerByIdentifiersStream(questionUuid, answer.uuid, null)
                        )
                    )
                    .subscribe((answer) => {
                        runInAction(() => {
                            this.showInReportAnswer = answer;
                        });

                        if (answer.contents === null) {
                            // Default should be to include (otherwise it can be overriden by question.defaultContents which will be set during stub)
                            this.answerController.onContentsChange(answer.uuid, '1', AnswerTouchState.TOUCHED);
                        }
                    })
            );
        }
    }

    public unmount(): void {
        super.unmount();

        this._subscriptions.clear();
    }

    public async onOpenFile(event: React.MouseEvent) {
        await this.markVisited();

        if (!this.attachmentQuestionsInteractor.isAttachmentModalAvailable()) {
            return; // Default behaviour of link click
        }

        event.preventDefault();

        this.attachmentQuestionsInteractor.showAttachmentModal({
            displayName: this.filename,
            url: this.url,
        });
    }

    public async onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files === null || e.target.files.length === 0 || this.answer === undefined) {
            return;
        }
        const files = e.target.files;
        const file = files[0];

        await this.onChangeFile(file);
    }

    public async onChangeFile(file: File, answer?: Answer) {
        if (answer === undefined) {
            answer = this.answer;
        }

        if (answer === undefined) {
            return;
        }
        const result = await this.attachmentUploadInteractor.uploadForAnswer(answer.uuid, file, {
            fileTypes: this.fileTypes,
            progressCallback: (progress) => this.updateProgress(progress),
        });

        // Reset progress bar when done
        this.updateProgress(null);

        if (!result.succeeded) {
            await this.clearAnswer(answer);
            this.throwAlert(result.message);
        } else {
            runInAction(() => {
                this.objectUrl = null;
            });
        }
        runInAction(() => {
            this.filledByAutomator = null;
        });
    }

    public async onClearClick() {
        if (this.answer !== undefined) {
            this.answerController.clearFileAndContents(this.answer.uuid);
            await this.answerInteractor.submit();
        }
    }

    public async markVisited() {
        if (this.answer !== undefined) {
            this.answerController.markVisited(this.answer.uuid);
            await this.answerInteractor.submit();
        }
    }

    public retry = async () => {
        if (this.answer === undefined) {
            return;
        }

        try {
            let contents = this.answer.contents ? parseImageAnswerContents(this.answer.contents) : null;

            if (contents?.state === ImageUploadState.VALIDATION_INSTITUTE_UPLOAD_FAILED && contents.path !== null) {
                this.answerController.onContentsChange(
                    this.answer.uuid,
                    JSON.stringify({
                        ...contents,
                        state: ImageUploadState.SYNCED,
                    }),
                    AnswerTouchState.TOUCHED
                );
                await this.answerInteractor.submit();
                return;
            }

            const file = await this.blobCacheInteractor.find(this.answer.uuid);
            if (file === null) {
                this.flashMessageBroadcaster.broadcast('Foto uploaden mislukt. Geen foto gevonden.', Type.Danger);
                return;
            }

            if (contents === null)
                contents = {
                    path: null,
                    state: ImageUploadState.UNSYNCED,
                    name: getFileName(file),
                    type: file.type,
                };

            const result = await this.attachmentUploadInteractor.uploadForAnswer(this.answer.uuid, file, {
                fileTypes: this.fileTypes,
                contents,
            });
            if (result.succeeded) {
                runInAction(() => {
                    this.file = null;
                });
            } else {
                this.flashMessageBroadcaster.broadcast(
                    result.message ?? 'Foto uploaden mislukt. Probeer het later nogmaals.',
                    Type.Danger
                );
            }
        } catch (e) {
            this.throwAlert();
        }
    };

    protected async clearAnswer(answer: Answer) {
        await this.blobCacheInteractor.remove(answer.uuid);
        this.answerController.clearFileAndContents(answer.uuid);
    }

    @action
    protected updateProgress(progress: number | null) {
        this.uploadProgress = progress;
    }

    protected throwAlert(error?: string) {
        if (error) {
            this.flashMessageBroadcaster.broadcast(error, Type.Danger);
        } else if (this.file !== null) {
            if (this.file.size > 15728640) {
                // 15 * 1,048,576 bytes
                this.alertIncorrectSize();
            } else {
                this.alertIncorrectType();
            }
        }
    }

    @action
    private alertIncorrectSize() {
        this.flashMessageBroadcaster.broadcast(
            'Fout bij het uploaden. De maximaal toegestane grote voor een bestand is 15 Mb.',
            Type.Danger
        );
    }

    @action
    private alertIncorrectType() {
        this.flashMessageBroadcaster.broadcast(
            'Fout bij het uploaden. Is het een correct formaat? Voor afbeeldingen kun je .jpg en .png uploaden en voor bijlagen .pdf.',
            Type.Danger
        );
    }

    @action
    public showAutomatorModal(shown = true) {
        this.automatorModalShown = shown;
    }

    public triggerAutomatorGroup() {
        const group = this.triggerableAutomatorGroup;
        if (!group) {
            return;
        }

        this.showAutomatorModal(false);

        const promise = this.automatorInteractor.triggerAutomatorGroup(this.appraisal.id, group.group);

        this.pollAutomatorPromise(promise);
    }

    @action
    private pollAutomatorPromise(promise: Promise<void>) {
        this.automatorPoll = promise;

        promise
            .then(() => {
                runInAction(() => {
                    this.automatorPoll = null;
                });
            })
            .catch(() => {
                runInAction(() => {
                    this.automatorPoll = null;
                    this.flashMessageBroadcaster.broadcast(
                        `Helaas is het niet gelukt om de bijlage "${this.question.contents}" automatisch op te halen. Neem contact op met taXapi om de bijlage alsnog op te vragen.`,
                        Type.Danger
                    );
                });
            });
    }
}
