import * as React from 'react';

import {
    PreValidationConclusiongState,
    ValidationConclusion,
} from '../../../../../business/validation/validation_conclusion_provider';
import {UnjudgedPage, UnjudgedPagesProvider} from '../unjudged_pages_provider';
import {
    ValidationMessage,
    ValidationMessageImportance,
    ValidationMessageMap,
} from '../../../../../business/validation/validation_message';

import {Appraisal} from '../../../../../models/appraisal';
import {AppraisalState} from '../../../../../enum/appraisal_state';
import {AppraisalValidationType} from '../../../../../enum/appraisal_validation_type';
import {Content} from '../../content';
import {Global} from '../../../../../../business/global_provider';
import {History} from 'history';
import {Link} from 'react-router-dom';
import {Loader} from '../../../components/loader';
import {Modal} from '../../questions/components/modal';
import {NetworkStatus} from '../../../../../business/network_status_provider';
import {PagePart} from '../../../../../models/page_part';
import {PagePartsSet} from '../../../../../models/page_parts_set';
import {QuestionSet} from '../../../../../models/question_set';
import {ReferenceObjectGroupAction} from '../actions/reference_objects_group_action';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {SidebarItem} from '../../../../../business/sidebar_tree_builder';
import {ValidationError} from '../../../../../models/validation_error';
import {ValidationErrorsContentPresenter} from './validation_errors_content_presenter';
import {ValidationErrorsList} from '../../validation_errors_list';
import {classNames} from '../../../../../../support/classnames';
import {isSet} from '../../../../../../support/is_set';
import {observer} from 'mobx-react';
import {PagePartContent} from '../../page_part_content';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {QuestionRenderingData} from '../../../../../models/question_rendering_data';
import {UserType} from '../../../../../enum/user_type';
import {PlausibilityRequestForm} from './plausibility_request_form';

interface OwnProps {
    history: History;
    global: Global;
    appraisal: Appraisal;
    questionSet: QuestionSet;
    validationErrors: ValidationError[];
    validationMessages: ValidationMessageMap;
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
    forceShowValidationMessages: boolean;
}

export const ValidationErrorsContent: React.FC<OwnProps> = observer(function ValidationErrorsContent(props) {
    const presenter = usePresenter(
        (container) =>
            new ValidationErrorsContentPresenter(
                props.global,
                container.business.photoAnswerRetryInteractor(props.appraisal, props.questionSet),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.networkStatusProvider,
                new UnjudgedPagesProvider(
                    container.business.sidebarTreeBuilder(props.appraisal, props.questionSet, props.pagePartsSet),
                    container.business.answerController(props.appraisal, props.questionSet),
                    [new ReferenceObjectGroupAction()]
                ),
                container.business.validationConclusionProvider(
                    props.appraisal,
                    props.questionSet,
                    props.pagePartsSet,
                    props.validationErrors,
                    props.renderingContext
                ),
                container.business.unsyncedAnswerRetryInteractor(props.appraisal, props.questionSet),
                props.appraisal,
                container.network.appraisalApi,
                container.network.taskHelper,
                container.business.flashMessageBroadcaster
            )
    );

    function renderMissingPages(unjudgedPages: UnjudgedPage[]): JSX.Element | null {
        const unjugedPagesMessages = unjudgedPages
            .map((unjudgedPage) => {
                const sidebarItem = unjudgedPage.sidebarItem;
                const name = sidebarItem.pagePart?.name ?? sidebarItem.question?.contents;
                if (!name) {
                    return null;
                }
                return (
                    <dl key={unjudgedPage.sidebarItem.link} className="alert-inline alert-hint ion-md-help-circle">
                        <dt>
                            <Link to={sidebarItem.link}>{name}</Link>
                        </dt>
                        <dd>
                            {unjudgedPage.message}
                            <div className="text-right">
                                {unjudgedPage.action === 'visit' ? (
                                    <Link className="btn btn-sm" to={sidebarItem.link}>
                                        Bezoek
                                    </Link>
                                ) : (
                                    <button
                                        className="btn btn-sm btn-primary"
                                        disabled={presenter.hiddenRenderingSidebarItem !== null}
                                        onClick={() => presenter.onMarkSeenClick(sidebarItem)}
                                    >
                                        {presenter.hiddenRenderingSidebarItem === sidebarItem ? (
                                            <>
                                                <Loader isSmall />
                                                <span className="ml-5">Markeer gezien</span>
                                            </>
                                        ) : (
                                            'Markeer gezien'
                                        )}
                                    </button>
                                )}
                            </div>
                        </dd>
                    </dl>
                );
            })
            .filter((c): c is JSX.Element => isSet(c));

        return unjugedPagesMessages.length === 0 ? null : <>{unjugedPagesMessages}</>;
    }

    function renderGenericMessages(validationConclusion: ValidationConclusion | null): JSX.Element | null {
        if (validationConclusion === null || validationConclusion.genericValidationErrors.length === 0) {
            return null;
        }
        return (
            <>
                {validationConclusion.genericValidationErrors.map((validationMessage, i) => {
                    return (
                        <dl key={i} className="alert-inline alert-hint ion-md-alert">
                            <dt>{validationMessage.title}</dt>
                            <dd>
                                {validationMessage.messages.length === 0
                                    ? validationMessage.fallbackMessage
                                    : validationMessage.messages.join('\n')}
                            </dd>
                        </dl>
                    );
                })}
            </>
        );
    }

    function renderMessageGroup(
        sidebarItem: SidebarItem,
        validationMessages: ValidationMessage[],
        type: ValidationMessageImportance
    ) {
        const messagesByIteration = new Map<string | null, ValidationMessage[]>();
        for (const validationMessage of validationMessages) {
            if (validationMessage.floorInfo != null) {
                messagesByIteration.set(validationMessage.floorInfo.iteration, [
                    ...(messagesByIteration.get(validationMessage.floorInfo.iteration) ?? []),
                    validationMessage,
                ]);
            } else {
                messagesByIteration.set(null, [...(messagesByIteration.get(null) ?? []), validationMessage]);
            }
        }

        return (
            <>
                {Array.from(messagesByIteration.entries()).map(([iteration, validationMessages]) => (
                    <dl
                        key={iteration}
                        className={classNames('alert-inline', {
                            'alert-danger ion-md-warning': type === ValidationMessageImportance.ERROR,
                            'alert-hint ion-md-alert': type === ValidationMessageImportance.WARNING,
                            'alert-info ion-md-information-circle': type === ValidationMessageImportance.INFO,
                        })}
                    >
                        <dt>
                            <Link
                                to={{
                                    pathname: sidebarItem.link,
                                    state: {
                                        focusValidation: true,
                                        questionUuids: validationMessages
                                            .map((validationMessage) =>
                                                validationMessage.question !== null
                                                    ? validationMessage.question.uuid
                                                    : null
                                            )
                                            .filter((uuid) => uuid !== null),
                                        pagePartFloorNumberIteration: iteration,
                                    },
                                }}
                            >
                                {sidebarItem.label}{' '}
                                {iteration !== null ? `(${validationMessages[0]?.floorInfo?.name ?? iteration})` : null}
                            </Link>
                        </dt>
                        <dd>
                            {validationMessages
                                .map((validationMessage: ValidationMessage) =>
                                    validationMessage.messages.length === 0
                                        ? validationMessage.fallbackMessage || ''
                                        : validationMessage.messages.join('\n')
                                )
                                .filter((value: string, index: number, self: string[]) => {
                                    return self.indexOf(value) === index;
                                })
                                .join('\n')}
                        </dd>
                    </dl>
                ))}
            </>
        );
    }

    function renderMessages(validationConclusion: ValidationConclusion | null): JSX.Element | null {
        if (validationConclusion === null) {
            return null;
        }

        const messages = validationConclusion.validationMap
            .map((validationMap, i) => {
                if (
                    validationMap[ValidationMessageImportance.ERROR].length === 0 &&
                    validationMap[ValidationMessageImportance.WARNING].length === 0 &&
                    validationMap[ValidationMessageImportance.INFO].length === 0
                ) {
                    return null;
                }

                return (
                    <React.Fragment key={i}>
                        {validationMap[ValidationMessageImportance.ERROR].length !== 0 &&
                            renderMessageGroup(
                                validationMap.sidebarItem,
                                validationMap[ValidationMessageImportance.ERROR],
                                ValidationMessageImportance.ERROR
                            )}
                        {validationMap[ValidationMessageImportance.WARNING].length !== 0 &&
                            renderMessageGroup(
                                validationMap.sidebarItem,
                                validationMap[ValidationMessageImportance.WARNING],
                                ValidationMessageImportance.WARNING
                            )}
                        {validationMap[ValidationMessageImportance.INFO].length !== 0 &&
                            renderMessageGroup(
                                validationMap.sidebarItem,
                                validationMap[ValidationMessageImportance.INFO],
                                ValidationMessageImportance.INFO
                            )}
                    </React.Fragment>
                );
            })
            .filter((element) => isSet(element));

        if (messages.length > 0) {
            return <>{messages}</>;
        }
        return null;
    }

    function renderPhotoAnswerConclusion(numUnsyncedPhotos: number | null): JSX.Element | null {
        if (presenter.photoAnswerRetryStatus !== null && presenter.photoAnswerRetryStatus.queued > 0) {
            return (
                <div className="alert-inline alert-danger ion-md-close-circle">
                    <p>
                        Bezig met uploaden van foto&apos;s.. {presenter.photoAnswerRetryStatus.done}/
                        {presenter.photoAnswerRetryStatus.total}
                    </p>
                </div>
            );
        }
        if (numUnsyncedPhotos === 0 || numUnsyncedPhotos === null) {
            return null;
        }
        if (numUnsyncedPhotos === 1) {
            return (
                <div className="alert-inline alert-danger ion-md-close-circle">
                    <p>
                        Er is nog 1 foto niet gesynchroniseerd. Zorg voor een goede internet verbinding zodat de taxatie
                        afgerond kan worden. Let op! Het kan zijn dat de foto nog op een ander apparaat opgeslagen is,
                        ga dan online met dat apparaat om foto&apos;s te synchroniseren.
                    </p>
                    <div className="text-left">
                        <a className="btn btn-sm" onClick={() => presenter.onRetryPhotoUploadClick()}>
                            Probeer nu te uploaden
                        </a>
                    </div>
                </div>
            );
        }
        return (
            <div className="alert-inline alert-danger ion-md-close-circle">
                <p>
                    Er zijn nog {numUnsyncedPhotos} foto&apos;s niet gesynchroniseerd. Zorg voor een goede internet
                    verbinding zodat de taxatie afgerond kan worden. Let op! Het kan zijn dat de foto&apos;s nog op een
                    ander apparaat opgeslagen zijn, ga dan online met dat apparaat om foto&apos;s te synchroniseren.
                </p>
                <div className="text-left">
                    <a className="btn btn-sm" onClick={() => presenter.onRetryPhotoUploadClick()}>
                        Probeer nu te uploaden
                    </a>
                </div>
            </div>
        );
    }

    function renderUnsavedAnswersConclusion(numUnsavedAnswers: number | null): JSX.Element | null {
        if (numUnsavedAnswers === 0 || numUnsavedAnswers === null) {
            return null;
        }
        return (
            <div className="alert-inline alert-danger ion-md-close-circle">
                <p>
                    Er zijn nog antwoorden niet gesynchroniseerd. Zorg voor een goede internet verbinding zodat de
                    taxatie afgerond kan worden.
                </p>
                <div className="text-left">
                    <a
                        className={classNames('btn btn-sm float-left', {
                            'has-loader disabled': presenter.savingAnswers,
                        })}
                        onClick={() => presenter.onAnswerSyncClick()}
                    >
                        {presenter.savingAnswers ? <Loader isSmall={true} /> : null}
                        Probeer nu te synchroniseren
                    </a>
                    <a
                        className={classNames('btn btn-sm float-right')}
                        onClick={() => presenter.onAnswerSyncCancelClick()}
                    >
                        Negeren
                    </a>
                </div>
            </div>
        );
    }

    function renderSuccessValidated() {
        return props.appraisal.status === AppraisalState.APPROVED ||
            props.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION ? (
            props.appraisal.status === AppraisalState.APPROVED ? (
                <p>
                    De opname is ingestuurd ter validatie bij het validatie-instituut. Je kan geen wijzigingen meer
                    aanbrengen, alleen het rapport inzien.
                </p>
            ) : (
                <p>
                    De opname is al voltooid en het rapport is goedgekeurd door het validatie-instituut. Je kan geen
                    wijzigingen meer aanbrengen, alleen het rapport inzien.
                </p>
            )
        ) : (
            <p>
                De opname kan voltooid worden, er zijn geen vragen of bijlage(n) die nog beantwoord, toegevoegd of
                bekeken moeten worden. Klik op &quot;voltooien&quot; om naar modelobjecten te gaan. Heb je de
                modelobjecten al geselecteerd dan krijg je op de volgende pagina de keuze om de modelobjecten opnieuw te
                bekijken of om het rapport direct in te sturen naar het validatie-instituut.
            </p>
        );
    }

    function renderSuccessNotValidated() {
        return props.appraisal.status === AppraisalState.APPROVED ||
            props.appraisal.status === AppraisalState.SUBMITTED_FOR_VALIDATION ? (
            <p>De opame is al voltooid.</p>
        ) : (
            <p>Door op &quot;voltooien&quot; te klikken wordt het rapport definitief.</p>
        );
    }

    function renderSuccess() {
        return (
            <>
                <h2>
                    {props.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED ? 'Afronden' : 'Indienen'}
                </h2>
                {props.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED
                    ? renderSuccessNotValidated()
                    : renderSuccessValidated()}
                {props.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED && renderPlausibilityForm()}
                {presenter.networkStatus === NetworkStatus.OFFLINE ? (
                    <div className="alert-inline alert-danger ion-md-close-circle">
                        <p>
                            Op dit moment is er geen actieve internet verbinding, Zorg voor een goede internet
                            verbinding zodat de taxatie afgerond kan worden.
                        </p>
                    </div>
                ) : null}
            </>
        );
    }

    function renderPlausibilityForm() {
        return (
            <>
                <h3>Controleur</h3>
                <p>
                    Indien er nog een <strong>plausibiliteitsverklaring</strong> opgesteld dient te worden, kunnen de
                    gegevens van de controlerend taxateur hier ingevuld worden. De controlerend taxateur ontvangt dan
                    een link om de plausibiliteitsverklaring op te stellen. Deze komt automatisch als bijlage in dit
                    rapport.
                </p>
                <PlausibilityRequestForm appraisal={props.appraisal} />
            </>
        );
    }

    function renderErrors() {
        const photoAnswerConclusion = renderPhotoAnswerConclusion(presenter.numUnsyncedPhotos);
        const unsavedAnswers = renderUnsavedAnswersConclusion(presenter.numUnsavedAnswers);
        const genericMessages = renderGenericMessages(presenter.validationConclusion);
        const messages = renderMessages(presenter.validationConclusion);
        const missingPages = renderMissingPages(presenter.unjudgedPages);

        const anyMessages =
            photoAnswerConclusion !== null ||
            unsavedAnswers !== null ||
            genericMessages !== null ||
            messages !== null ||
            missingPages !== null;

        return (
            <>
                <h2>Controle op invoer</h2>
                {anyMessages ? (
                    <>
                        <p>De volgende vragen of bijlage(n) moeten nog beantwoord, toegevoegd of bekeken worden:</p>
                        {photoAnswerConclusion}
                        {unsavedAnswers}
                        {genericMessages}
                        {messages}
                        {missingPages}
                    </>
                ) : (
                    <p>Er zijn geen vragen of bijlage(n) die nog beantwoord, toegevoegd of bekeken moeten worden.</p>
                )}

                {props.appraisal.validationType === AppraisalValidationType.VALIDATED
                    ? renderPreValidationErrors()
                    : null}
            </>
        );
    }

    function renderPreValidationErrors(): JSX.Element | null {
        if (!props.questionSet.reportDefintionConfig.preValidationMessages) {
            return null;
        }
        if (props.appraisal.validationType !== AppraisalValidationType.VALIDATED) {
            return null;
        }
        if (presenter.validationConclusion === null) {
            return null;
        }
        if (presenter.validationConclusion.preValidationErrors.state === PreValidationConclusiongState.WAITING) {
            return null;
        }
        if (presenter.validationConclusion.preValidationErrors.state === PreValidationConclusiongState.ERRORED) {
            return (
                <>
                    <h3>Controleverslag NWWI</h3>
                    <div className="alert-inline alert-danger ion-md-close-circle">
                        <p>Het ophalen van het controleverslag van NWWI is mislukt.</p>
                    </div>
                </>
            );
        }
        if (presenter.validationConclusion.preValidationErrors.state === PreValidationConclusiongState.LOADING) {
            return (
                <>
                    <h2>Controleverslag NWWI</h2>
                    <div className="text-center">
                        <Loader />
                        <p>Bezig met het controleverslag van NWWI, een moment a.u.b.</p>
                    </div>
                </>
            );
        }

        return (
            <>
                <h2>Pre-validatie</h2>
                {presenter.hasPreValidationError ? (
                    <>
                        <p>De volgende aandachtspunten zijn er nog vanuit het validatie-instituut:</p>
                        <ValidationErrorsList
                            validationErrors={presenter.validationConclusion.preValidationErrors.errors}
                            questionSet={props.questionSet}
                        />
                    </>
                ) : (
                    <p>Er zijn geen aandachtspunten vanuit het validatie-instituut.</p>
                )}
            </>
        );
    }

    function renderAcceptedTermsWarning() {
        const userType = props.global.userType;

        return (
            !presenter.validationConclusion?.gateAllowsValidation &&
            !presenter.hasForcedAcceptedTerms && (
                <>
                    <div className="alert-inline alert-danger ion-md-close-circle">
                        {props.appraisal.validationType === AppraisalValidationType.NOT_VALIDATED ? (
                            <p>
                                Het rapport kan nog niet gegenereerd worden omdat de opdrachtgever de
                                opdrachtvoorwaarden eerst dient te accepteren.
                            </p>
                        ) : userType !== null && [UserType.JUNIOR_APPRAISER].includes(userType) ? (
                            <p>
                                Helaas heb je niet de juiste rechten om deze taxatie in te sturen. De meester is diegene
                                die het rapport definitief mag insturen.
                            </p>
                        ) : (
                            <>
                                <p>
                                    Er kunnen nog geen modelobjecten opgevraagd worden omdat de opdrachtgever de
                                    opdrachtvoorwaarden eerst dient te accepteren.
                                </p>
                                <div className="text-right">
                                    <button
                                        className="btn btn-sm btn-warning"
                                        onClick={() => presenter.setModalAcceptTermsVisible(true)}
                                    >
                                        Markeer geaccepteerd
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                    <Modal
                        visible={presenter.modalAcceptTermsVisible}
                        onHide={() => presenter.setModalAcceptTermsVisible(false)}
                    >
                        <div className="modal-header">
                            <h2>Opdrachtvoorwaarden handmatig accepteren</h2>
                        </div>
                        <div className="modal-body pb-0">
                            <p>
                                De opdrachtgever heeft de opdrachtvoorwaarden (of de gewijzigde opdrachtvoorwaarden)
                                (nog) niet geaccepteerd via taXapi. Om toch modelobjecten op te kunnen halen en het
                                rapport te kunnen insturen kun je de opdrachtvoorwaarden hierbij handmatig markeren als
                                geaccepteerd.
                            </p>
                            <p>
                                Je dient zelf te zorgen dat er een exemplaar van de getekende opdrachtvoorwaarden aan je
                                dossier wordt toegevoegd.
                            </p>
                            <div className="form-group checkbox">
                                <input
                                    type="checkbox"
                                    id="manual-accept"
                                    checked={presenter.acceptTermsManually}
                                    onChange={(e) => presenter.setAcceptTermsManually(e.target.checked)}
                                />
                                <label htmlFor="manual-accept">
                                    Ik verklaar dat de opdrachtgever akkoord is met de opdrachtvoorwaarden.
                                </label>
                                {presenter.modalAcceptTermsShowErrors && !presenter.acceptTermsManually && (
                                    <span className="invalid-feedback" role="alert">
                                        Dit veld is vereist.
                                    </span>
                                )}
                            </div>
                            <div className="detail-block">
                                <p>
                                    Of verstuur een herinnering naar de opdrachtgever voor het accepteren van de
                                    opdrachtvoorwaarden:
                                </p>
                                <div className="text-right">
                                    <button className="btn btn-sm" onClick={() => presenter.triggerRemindTerms()}>
                                        <span className="ion-md-send mr-2"></span>
                                        Verzend herinnering
                                        {presenter.loadingRemindTerms && <Loader isSmall />}
                                    </button>
                                </div>
                                <div className="text-right">
                                    <span className="status-label">Tip</span>
                                    Dit kan ook via de
                                    <span className="action-button-reference">
                                        <span className="ion-md-more"></span>
                                    </span>
                                    -knop op de <a href={`/appraisals/${props.appraisal.id}`}>overzicht pagina</a> voor
                                    deze taxatie.
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer mt-0">
                            <button
                                className="btn btn-secondary"
                                onClick={() => presenter.setModalAcceptTermsVisible(false)}
                            >
                                Sluiten
                            </button>
                            <button className="btn btn-primary" onClick={() => presenter.triggerAcceptTerms()}>
                                {presenter.loadingAcceptTerms ? <Loader /> : 'Accepteren'}
                            </button>
                        </div>
                    </Modal>
                </>
            )
        );
    }

    function renderAttachmentsCheckedWarning() {
        return (
            !presenter.hasCheckedAttachments && (
                <>
                    <div className="alert-inline alert-danger ion-md-close-circle">
                        <p>
                            De bijlagen zijn nog niet gecontroleerd. Je kan dit zelf doen en deze markeren als
                            nagekeken, of wachten tot de medewerkers van taXapi dit hebben gedaan.
                        </p>
                        <div className="text-right">
                            <button
                                className="btn btn-sm btn-warning"
                                onClick={() => presenter.triggerAttachmentCheck()}
                                disabled={presenter.loadingCheckAttachments}
                            >
                                Markeer nagekeken
                            </button>
                        </div>
                    </div>
                </>
            )
        );
    }

    function renderConclusion() {
        return (
            <>
                {renderErrors()}
                {!presenter.hasError && !presenter.hasPreValidationError && renderSuccess()}
                {renderAttachmentsCheckedWarning()}
                {renderAcceptedTermsWarning()}
            </>
        );
    }

    function renderHiddenSidebarItem() {
        const {hiddenRenderingSidebarItem} = presenter;

        if (hiddenRenderingSidebarItem === null || !hiddenRenderingSidebarItem.question) {
            return;
        }

        const pagePart = hiddenRenderingSidebarItem.pagePart;
        if (pagePart) {
            return (
                <div style={{display: 'none'}}>
                    <PagePartContent
                        key={pagePart.uuid}
                        global={props.global}
                        appraisal={props.appraisal}
                        pagePartsUuid={pagePart.uuid}
                        iteration={undefined}
                        questionSet={props.questionSet}
                        validationErrors={props.validationErrors}
                        validationMessages={props.validationMessages}
                        onChange={() => {
                            /* Noop */
                        }}
                        pagePartsSet={props.pagePartsSet}
                        renderingContext={props.renderingContext}
                        forceShowValidationMessages={props.forceShowValidationMessages}
                        questionRenderingData={null}
                    />
                </div>
            );
        }

        return (
            <div style={{display: 'none'}}>
                <Content
                    key={hiddenRenderingSidebarItem.question.uuid}
                    history={props.history}
                    global={props.global}
                    appraisal={props.appraisal}
                    uuid={hiddenRenderingSidebarItem.question.uuid}
                    questionSet={props.questionSet}
                    validationErrors={props.validationErrors}
                    validationMessages={props.validationMessages}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    iteration={hiddenRenderingSidebarItem?.iteration ?? undefined}
                    onChange={() => {
                        /* Noop */
                    }}
                    pagePartsSet={props.pagePartsSet}
                    activePagePart={props.activePagePart}
                    renderingContext={props.renderingContext}
                    questionRenderingData={props.questionRenderingData}
                />
            </div>
        );
    }

    return presenter.numUnsyncedPhotos !== null ? (
        <>
            {renderConclusion()}
            {renderHiddenSidebarItem()}
        </>
    ) : (
        <div className="row">
            <div className="col-12 text-center">
                <Loader icon="c-icon c-icon-finished" addSpace />
                <h3>Bezig met het uitvoeren van de controle...</h3>
            </div>
        </div>
    );
});
