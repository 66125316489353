import {Answer} from '../../models/answer';
import {TextAIContextBuilder} from './textai_context_builder';
import {
    V3ReferenceSet,
    V3ReferenceSetsProvider,
} from '../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/reference_sets/reference_sets_provider';
import {createReferenceObjectDataForAnswer} from '../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/create_reference_object_data';
import {ExplanationDataProvider} from '../../appraise/ui/content/questions/advanced/reference_objects_question/v3/internal/explanation_appraiser_generator/explanation_data_provider';
import {isEmpty} from '../../../support/util';
import {combineLatest, debounceTime, EMPTY, map, Observable, skip} from 'rxjs';
import {flattenTree} from '../../../support/question_answer_tree';
import {NormalQuestionType} from '../../enum/question_type';
import {AnswerController} from '../answering/answer_controller';
import {TechnicalReference} from '../../enum/technical_reference';
import {QuestionSet} from '../../models/question_set';
import {getNewestValidAnswer, getUniqueBijgebouwen} from '../support/bijgebouwen_as_string_provider';

export class DefaultTextAIReferenceObjectContextBuilder implements TextAIContextBuilder {
    private referenceSets: V3ReferenceSet[] | null = null;

    constructor(
        private referenceSetsProvider: V3ReferenceSetsProvider,
        private explanationDataProvider: ExplanationDataProvider,
        private answerController: AnswerController,
        private questionSet: QuestionSet
    ) {
        this.referenceSetsProvider.referenceSets().subscribe((referenceSets) => {
            this.referenceSets = referenceSets;
        });
    }

    public getContext(answer?: Answer): {[key: string]: unknown} {
        if (!answer || !this.referenceSets) {
            return {};
        }

        const referenceObjectDataWithSet = createReferenceObjectDataForAnswer(
            this.referenceSets,
            answer.questionUuid,
            answer.parentUuid
        );

        if (referenceObjectDataWithSet === null) {
            return {};
        }

        const explanationData = this.explanationDataProvider.get(
            referenceObjectDataWithSet.referenceSet.surfaceArea,
            referenceObjectDataWithSet.referenceSet.plotArea,
            referenceObjectDataWithSet.referenceSet.volume,
            referenceObjectDataWithSet.referenceSet.energyLabel
        );

        const referenceObject = referenceObjectDataWithSet.data.referenceObjectAnswer.referenceObject;

        const ifNotComparable = (value?: string, explanation?: string) =>
            value === 'Vergelijkbaar' || value === undefined || value === ''
                ? undefined
                : explanation
                ? `${value}, ${explanation}`
                : value;

        const bouwjaarComparable =
            referenceObject.bouwjaar !== null && explanationData.object.buildYear !== null
                ? referenceObject.bouwjaar > explanationData.object.buildYear
                    ? referenceObject.bouwjaar - explanationData.object.buildYear <= 10
                    : explanationData.object.buildYear - referenceObject.bouwjaar <= 10
                : false;

        const overigeBijgebouwenQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.OBJECT_OMSCHRIJVING
        );
        const uniqueOverigeBijgebouwen = getNewestValidAnswer(
            overigeBijgebouwenQuestions,
            this.answerController,
            (a) => a !== null && a.contents === '1'
        );

        const bijgebouwenQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.OBJECT_OMSCHRIJVING_BIJGEBOUW
        );
        const bijgebouwenAnswerOptions = bijgebouwenQuestions.flatMap((q) => q.answerOptions);

        const aanbouwenQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.OBJECT_OMSCHRIJVING_AANBOUW
        );

        const dakkapellenArray = bijgebouwenAnswerOptions
            .map((ao) =>
                ao.technicalReference?.includes(TechnicalReference.OBJECT_OMSCHRIJVING_BIJGEBOUW_DAKKAPELLEN)
                    ? ao.contents
                    : null
            )
            .filter((ao) => ao !== null && true) as string[];

        const floorQuestions = this.questionSet.findQuestionsByTechnicalReference(
            TechnicalReference.FLOOR_LAYOUT_PARENT
        );

        const floorIterations = this.answerController.answersForQuestionUuids(floorQuestions.map((q) => q.uuid));

        const validFloorIterations = floorIterations.filter((answer) => {
            return !answer.isDeleted && answer.iteration !== null;
        });

        const uniqueBijgebouwenOnFloors = validFloorIterations.flatMap((floor) => {
            return getUniqueBijgebouwen(
                bijgebouwenQuestions,
                bijgebouwenAnswerOptions,
                dakkapellenArray,
                this.answerController,
                floor
            );
        });

        const uniqueBijgebouwen = getUniqueBijgebouwen(
            bijgebouwenQuestions,
            bijgebouwenAnswerOptions,
            dakkapellenArray,
            this.answerController
        );

        const uniqueBijgebouwenWithoutDuplicates = uniqueBijgebouwen.filter(
            (bijgebouw) => bijgebouw && !uniqueBijgebouwenOnFloors.includes(bijgebouw)
        );

        const uniqueAanbouwen = getNewestValidAnswer(
            aanbouwenQuestions,
            this.answerController,
            (a) => a !== null && a.contents === '1'
        );

        const allBijgebouwen = [
            ...uniqueBijgebouwenWithoutDuplicates,
            ...uniqueOverigeBijgebouwen,
            ...uniqueAanbouwen,
            ...uniqueBijgebouwenOnFloors,
        ]
            .map((bijgebouw) => bijgebouw?.toLocaleLowerCase())
            .filter((bijgebouw) => bijgebouw !== undefined);

        const hideEnergielabel =
            referenceObject.energielabel === '' ||
            referenceObject.energielabel === 'Geen energielabel' ||
            explanationData.object.energyLabel === undefined ||
            explanationData.object.energyLabel === null ||
            referenceObject.energielabel === explanationData.object.energyLabel;

        const hideBijgebouwen = referenceObject.aanbouwStatus === 'Vergelijkbaar';

        return {
            // Clear out these default values
            Vragen: undefined,
            Taxatie: undefined,
            getaxeerde: {
                Adres: explanationData.appraisal.addressLine,
                Bouwjaar: bouwjaarComparable ? undefined : explanationData.object.buildYear,
                energielabel: hideEnergielabel
                    ? undefined
                    : explanationData.object.energyLabel !== undefined
                    ? explanationData.object.energyLabel
                    : 'Niet bekend',
                Gebruiksoppervlakte: explanationData.object.floorArea
                    ? `${explanationData.object.floorArea} m²`
                    : undefined,
                Perceeloppervlakte: explanationData.object.plotArea
                    ? `${explanationData.object.plotArea} m²`
                    : undefined,
                Bijgebouwen: !hideBijgebouwen ? allBijgebouwen.join(', ') : undefined,
            },
            referentieobject: {
                Adres: `${referenceObject.adres.straat} ${referenceObject.adres.huisnummer} ${referenceObject.adres.huisnummerToevoeging}`,
                Bouwjaar: bouwjaarComparable ? undefined : referenceObject.bouwjaar,
                energielabel: hideEnergielabel
                    ? undefined
                    : referenceObject.energielabel !== ''
                    ? referenceObject.energielabel
                    : 'Niet bekend',
                Gebruikersoppervlakte: referenceObject.gebruiksOppervlakte
                    ? `${referenceObject.gebruiksOppervlakte} m²`
                    : undefined,
                Perceeloppervlakte: referenceObject.perceelOppervlakte
                    ? `${referenceObject.perceelOppervlakte} m²`
                    : undefined,
                Onderhoudssituatie:
                    referenceObject.onderhoudssituatie !== 'Goed' ? referenceObject.onderhoudssituatie : undefined,
                'Verschil in onderhoudssituatie t.o.v. taxatieobject': ifNotComparable(
                    referenceObject.onderhoudsSituatieStatus,
                    referenceObject.onderhoudsSituatieUitleg
                ),
                'Verschil in woningtype t.o.v. taxatieobject': ifNotComparable(
                    referenceObject.woningTypeStatus,
                    referenceObject.woningTypeUitleg
                ),
                'Verschil in mate van luxe t.o.v. taxatieobject': ifNotComparable(
                    referenceObject.mateVanLuxeStatus,
                    referenceObject.mateVanLuxeUitleg
                ),
                'Verschil in mate van doelmatigheid t.o.v. taxatieobject': ifNotComparable(
                    referenceObject.mateVanDoelmatigheidStatus,
                    referenceObject.mateVanDoelmatigheidUitleg
                ),
                'Verschil in ligging t.o.v. taxatieobject': ifNotComparable(
                    referenceObject.liggingStatus,
                    referenceObject.liggingUitleg
                ),
                Bijgebouwen: !hideBijgebouwen ? referenceObject.aanbouw : undefined,
                'Verschil in bijgebouwen t.o.v. taxatieobject': ifNotComparable(
                    referenceObject.aanbouwStatus,
                    referenceObject.aanbouwUitleg
                ),
                'Verschil in eigendomssituatie t.o.v. taxatieobject': ifNotComparable(
                    referenceObject.eigendomssituatieStatus,
                    referenceObject.eigendomssituatieUitleg
                ),
                'Andere wezenlijke verschillen': !isEmpty(referenceObject.andereWezenlijkeVerschillenUitleg)
                    ? referenceObject.andereWezenlijkeVerschillenUitleg
                    : undefined,
            },
        };
    }

    public getPromptForAnswer() {
        return 'Kun je mij een samenvatting geven in één alinea met eerst kort de overeenkomsten die belangrijk zijn en vervolgens de verschillen tussen beide objecten gedetailleerd genoemd. Waarbij de nadruk meer ligt op de verschillen dan de overeenkomsten. Wanneer de letter van het energielabel niet hetzelfde is, dan is het niet vergelijkbaar. Benoem ook duidelijk of het over het taxatieobject of het referentieobject gaat.';
    }

    public getRefreshTriggerStream(answer?: Answer): Observable<void> {
        if (!answer || !this.referenceSets) {
            return EMPTY;
        }

        const referenceObjectDataWithSet = createReferenceObjectDataForAnswer(
            this.referenceSets,
            answer.questionUuid,
            answer.parentUuid
        );

        if (referenceObjectDataWithSet === null) {
            return EMPTY;
        }

        return combineLatest(
            flattenTree(referenceObjectDataWithSet.data.treeItem)
                .filter(
                    (item) =>
                        item.question.type !== NormalQuestionType.REFERENCE_OBJECT_EXPLANATION && item.answer !== null
                )
                .map((item) =>
                    this.answerController.answerByIdentifiersStream(
                        item.question.uuid,
                        (item.answer as Answer).parentUuid,
                        (item.answer as Answer).iteration
                    )
                )
        ).pipe(
            skip(1),
            debounceTime(500),
            map(() => {
                // Return void
            })
        );
    }
}
