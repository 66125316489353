import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
} from 'chart.js';
import moment from 'moment';
import 'moment/locale/nl';
moment.locale('nl');

import 'chartjs-adapter-moment';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
);

const chartElement = document.getElementById('statistics-graph');

if (chartElement !== null) {
    function sumByMonth(data) {
        let grouped = {};
        if (data === null || data === undefined) {
            return grouped;
        }
        data.forEach((obj) => {
            const date = new Date(obj.date);
            date.setDate(1);
            date.setUTCHours(0, 0, 0, 0);
            const startOfMonth = date.toISOString();
            if (!grouped[startOfMonth]) grouped[startOfMonth] = Number(obj.value);
            else grouped[startOfMonth] += Number(obj.value);
        });

        return Object.entries(grouped).map(([date, value]) => ({date, value}));
    }

    const chartContext = chartElement.getContext('2d');

    if (chartContext !== null) {
        moment.locale('nl');

        let datasets = [];
        if (STATISTICS_GRAPH.listAppraiserAppraisalsPastThreeMonths !== null) {
            datasets.push({
                label: 'Aantal taxaties',
                data: sumByMonth(STATISTICS_GRAPH.listAppraiserAppraisalsPastThreeMonths),
                backgroundColor: 'rgba(45, 227, 219, 0.4)',
                borderColor: 'rgb(45, 227, 219)',
                borderWidth: 2,
                fill: false,
                barThickness: 20,
            });
        }
        if (STATISTICS_GRAPH.listOrganisationAppraiserAppraisalsPastThreeMonths !== null) {
            datasets.push({
                label: 'Aantal taxaties organisatie',
                data: sumByMonth(STATISTICS_GRAPH.listOrganisationAppraiserAppraisalsPastThreeMonths),
                backgroundColor: 'rgba(45, 227, 219, 0.2)',
                borderColor: 'rgb(45, 227, 219, 0.8)',
                borderWidth: 2,
                fill: false,
                barThickness: 20,
            });
        }
        if (STATISTICS_GRAPH.listRequestedCorrectionPastThreeMonths !== null) {
            datasets.push({
                label: 'Correctieverzoeken',
                data: sumByMonth(STATISTICS_GRAPH.listRequestedCorrectionPastThreeMonths),
                backgroundColor: 'rgba(255, 205, 86, 0.4)',
                borderColor: 'rgb(255, 205, 86)',
                borderWidth: 2,
                fill: false,
                barThickness: 20,
            });
        }
        if (STATISTICS_GRAPH.listOrganisationRequestedCorrectionPastThreeMonths !== null) {
            datasets.push({
                label: 'Correctieverzoeken organisatie',
                data: sumByMonth(STATISTICS_GRAPH.listOrganisationRequestedCorrectionPastThreeMonths),
                backgroundColor: 'rgba(255, 205, 86, 0.2)',
                borderColor: 'rgb(255, 205, 86, 0.8)',
                borderWidth: 2,
                fill: false,
                barThickness: 20,
            });
        }

        var myLineChart = new Chart(chartContext, {
            type: 'bar',
            indexAxis: 'date',
            data: {
                datasets: datasets,
            },
            options: {
                parsing: {
                    xAxisKey: 'date',
                    yAxisKey: 'value',
                },
                scales: {
                    xAxis: {
                        type: 'time',
                        position: 'bottom',
                        time: {
                            tooltipFormat: 'MMMM YY',
                            unit: 'month',
                            round: 'day',
                        },
                    },
                },
                scale: {
                    ticks: {
                        precision: 0,
                    },
                },
                layout: {
                    padding: 20,
                },
            },
        });
    }
}
