import * as React from 'react';

import {AttachmentQuestionPresenter, AttachmentQuestionProps} from './attachment_question_presenter';

import {classNames} from '../../../../../../support/classnames';
import {observer} from 'mobx-react';
import {simpleQuestionPresenterConstructorParametersFactory} from '../simple/simple_question_presenter';
import {Modal} from '../components/modal';
import {formatMoney} from '../../../support/format_money';
import {AppraisalValidationType} from '../../../../../enum/appraisal_validation_type';
import {AutomatorDispatchGroup} from '../../../../../../create_appraisal/preferences/appraisal_costs';
import {Loader} from '../../../components/loader';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {SimpleTooltip} from '../../../../../components/tooltip';
import {BooleanQuestion} from '../simple/boolean_question';

export interface AttachmentQuestionAvatarData {
    iconClass: string | null;
    tooltip: string | null;
    initials: string | null;
}

export const AttachmentQuestion: React.FunctionComponent<AttachmentQuestionProps> = observer((props) => {
    const presenter = usePresenter(
        (container) =>
            new AttachmentQuestionPresenter(
                props.fileTypes,
                container.globalProvider,
                container.business.blobCacheInteractor(),
                container.business.attachmentUploadInteractor(props.appraisal, props.questionSet),
                container.business.answerInteractor(props.appraisal, props.questionSet),
                container.business.attachmentQuestionsInteractor(props.appraisal, props.questionSet),
                container.business.flashMessageBroadcaster,
                container.business.automatorInteractor(props.appraisal, props.questionSet),
                ...simpleQuestionPresenterConstructorParametersFactory(props, container)
            )
    );

    function renderPreview() {
        return presenter.url && presenter.isImage ? (
            <span className="list-preview">
                <img src={presenter.url} />
            </span>
        ) : null;
    }

    function renderProgressBar() {
        const progress = presenter.uploadProgress;
        if (progress === 1 || progress == 0 || progress === null) {
            return null;
        }

        return (
            <span className="list-progress">
                <div className="uppy uppy-ProgressBar" style={{position: 'fixed'}}>
                    <div
                        className="uppy-ProgressBar-inner"
                        style={{
                            width: progress ? `${progress * 100}%` : '0%',
                            display: progress ? undefined : 'none',
                        }}
                    />
                    <div className="uppy-ProgressBar-percentage">0</div>
                </div>
            </span>
        );
    }

    function renderButtons() {
        if (presenter.loading) {
            return null;
        }

        return (
            <>
                <div className="list-item-attachment-buttons">
                    {presenter.url ? (
                        <>
                            <button
                                className="btn btn-upload"
                                disabled={props.disabled || presenter.isDisabled}
                                onClick={(e) => {
                                    if (
                                        e.target instanceof HTMLElement &&
                                        e.target.firstChild instanceof HTMLInputElement &&
                                        confirm('Zeker dat je dit bestand wil vervangen?')
                                    ) {
                                        e.target.firstChild.click();
                                    }
                                }}
                            >
                                {renderFileUpload()}
                                Vervang
                                <br />
                                bijlage
                            </button>
                            {presenter.canControlShowInReport !== null &&
                                presenter.showInReportQuestion !== null &&
                                presenter.answer !== undefined && (
                                    // This boolean question is rendered as button
                                    <SimpleTooltip
                                        content={
                                            presenter.showInReport
                                                ? 'Bijlage opgenomen in rapport.'
                                                : 'Bijlage niet opgenomen in rapport.'
                                        }
                                    >
                                        <div className="btn btn-check">
                                            <BooleanQuestion
                                                key={presenter.showInReportQuestion.uuid}
                                                appraisal={props.appraisal}
                                                question={presenter.showInReportQuestion}
                                                questionSet={props.questionSet}
                                                validationErrors={props.validationErrors}
                                                validationMessages={props.validationMessages}
                                                parentAnswerUuid={presenter.answer.uuid}
                                                adaptedDefaultValues={props.adaptedDefaultValues}
                                                hiddenQuestionTypes={props.hiddenQuestionTypes}
                                                pagePartsSet={props.pagePartsSet}
                                                activePagePart={props.activePagePart}
                                                hideLabel={true}
                                                renderingContext={props.renderingContext}
                                                questionRenderingData={props.questionRenderingData}
                                                forceShowValidationMessages={props.forceShowValidationMessages}
                                            />
                                        </div>
                                    </SimpleTooltip>
                                )}
                            <button
                                className="btn btn-clear ion-md-trash"
                                onClick={() => presenter.onClearClick()}
                                disabled={props.disabled || presenter.isDisabled}
                            />
                        </>
                    ) : (
                        <>
                            {presenter.triggerableAutomatorGroup && (
                                <button className="btn btn-upload" onClick={() => presenter.showAutomatorModal()}>
                                    Automatisch
                                    <br />
                                    ophalen
                                </button>
                            )}
                            <label className="btn btn-upload">
                                {renderFileUpload()}
                                Upload een
                                <br />
                                bijlage
                            </label>
                        </>
                    )}
                </div>
            </>
        );
    }

    function renderFileData() {
        return (
            <>
                {presenter.url && (
                    <a
                        className="list-item-attachment-download"
                        onClick={(e) => presenter.onOpenFile(e)}
                        href={presenter.url}
                        target="_blank"
                        rel="noreferrer"
                    />
                )}
                {presenter.hasUserAvatar && renderUserAvatar()}
                <span
                    className={classNames('list-avatar', {
                        'list-avatar-second': presenter.hasUserAvatar,
                        'list-avatar-download': presenter.url !== null,
                        'list-avatar-active': presenter.isActive,
                        'ion-md-cloud-upload': presenter.url === null,
                        'ion-md-cloud-download': presenter.url !== null,
                    })}
                >
                    {presenter.loading && (
                        <span className="list-avatar-loader">
                            <Loader addSpace={false} />
                        </span>
                    )}
                    {presenter.shouldShowUnreadIndicator &&
                        presenter.answer?.file &&
                        presenter.answer?.isVisited === false && <span className="unread-notification" />}
                </span>
                <span
                    className={classNames('form-group', presenter.filledByAutomator, {
                        'automation-filled': presenter.filledByAutomator !== null,
                        'list-title-download': presenter.url !== null,
                    })}
                >
                    {props.question.contents}
                </span>
                <small
                    className={classNames('list-details', {
                        'list-details-download': presenter.url !== null,
                    })}
                >
                    {presenter.filename ? presenter.filename : 'Nog geen .pdf bestand geüpload'}
                    {presenter.showRetryButton ? ' - Er is iets mis met deze pdf. Upload een nieuwe pdf.' : null}
                </small>
            </>
        );
    }

    function renderUserAvatar() {
        return (
            <SimpleTooltip content={presenter.avatarData.tooltip} placement="top">
                <span
                    className={classNames('list-avatar', 'list-user-avatar', presenter.avatarData.iconClass)}
                    data-type="info"
                >
                    {presenter.avatarData.initials}
                </span>
            </SimpleTooltip>
        );
    }

    function renderFileUpload() {
        return (
            <input
                type="file"
                name="files[]"
                style={{display: 'none'}}
                disabled={props.disabled || presenter.isDisabled}
                onChange={(e) => presenter.onFileChange(e)}
            />
        );
    }

    function renderAutomatorModal() {
        const group = presenter.triggerableAutomatorGroup;

        if (!presenter.automatorModalShown || !group) {
            return null;
        }

        const totalCosts = group.costLines.reduce((total, line) => total + line.costCents, 0);
        const willCauseValidationInstituteRegistration =
            props.appraisal.validationType === AppraisalValidationType.VALIDATED &&
            props.appraisal.reportNumber === null &&
            group.group === AutomatorDispatchGroup.FUNDERINGSRISICO;

        return (
            <Modal visible>
                <div className="modal-header">
                    <div className="d-flex flex-column align-items-center mb-2 w-100">
                        <span
                            className="ion-md-code-download"
                            style={{fontSize: 50, marginTop: -20, color: 'var(--color-primary)'}}
                        />
                        <h2>{props.question.contents} automatisch ophalen</h2>
                    </div>
                </div>
                <div className="modal-body pb-0 pt-1">
                    {totalCosts > 0 && (
                        <>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Beschrijving</th>
                                        <th className="text-right">Tarief</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {group.costLines.map((line) => (
                                        <tr key={line.costType}>
                                            <td>{line.displayName}</td>
                                            <td className="text-right">
                                                {line.originalCostCents && line.originalCostCents > line.costCents && (
                                                    <span className="discount-original-price pr-2">
                                                        {formatMoney(line.originalCostCents / 100, {decimals: 2})}
                                                    </span>
                                                )}{' '}
                                                {formatMoney(line.costCents / 100, {decimals: 2})}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                    <p>
                        Wil je deze bijlage ophalen?{' '}
                        {totalCosts > 0 && 'Hiervoor zullen bovenstaande kosten voor worden gerekend. '}
                        {willCauseValidationInstituteRegistration &&
                            'Het ophalen van deze bijlage zal er voor zorgen dat de taxatie geregistreerd wordt bij het NWWI. '}
                        Dit kan een paar minuten duren.
                    </p>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-secondary" onClick={() => presenter.showAutomatorModal(false)}>
                        Sluiten
                    </button>
                    <button className="btn btn-primary" onClick={() => presenter.triggerAutomatorGroup()}>
                        Ophalen
                    </button>
                </div>
            </Modal>
        );
    }

    if (presenter.isHidden) {
        return null;
    }

    return (
        <div
            className={classNames('list-item-attachment', {
                uploaded: presenter.filename !== null,
                'list-item-attachment-should-refresh': presenter.showRetryButton,
                'list-item-attachment-multiple-avatar': presenter.hasUserAvatar,
            })}
        >
            {renderFileData()}
            {renderButtons()}
            {renderPreview()}
            {renderProgressBar()}
            {renderAutomatorModal()}
        </div>
    );
});
