import * as React from 'react';

import {PdfViewer} from './components/pdf_viewer';
import {observer} from 'mobx-react';
import {classNames} from '../../../../../support/classnames';

export interface AttachmentPreviewWidgetProps {
    displayName: string | null;
    url: string | null;
}

export const AttachmentPreviewWidget: React.FC<AttachmentPreviewWidgetProps & {onDiscard: () => void}> = observer(
    function AttachmentPreviewWidget({displayName, url, onDiscard}) {
        const [closing, setClosing] = React.useState(false);

        return (
            <div className={classNames('pdf-overlay', {closing})}>
                <div className="pdf-overlay-title">
                    <h2>Bijlagen voorvertoning</h2>
                    <span className="pdf-overlay-title-sub">{displayName}</span>
                    <div className="pdf-overlay-buttons">
                        <a href={url ?? ''} target="_blank" rel="noreferrer">
                            <span className="ion-md-open"></span>
                        </a>
                        <button
                            onClick={() => {
                                setClosing(true);
                                setTimeout(() => {
                                    onDiscard();
                                }, 300);
                            }}
                        >
                            <span className="ion-md-close"></span>
                        </button>
                    </div>
                </div>
                <div className="pdf-overlay-body">
                    <PdfViewer src={url ?? ''} showToolbar />
                </div>
            </div>
        );
    }
);
