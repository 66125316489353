import {action, makeObservable, observable, runInAction} from 'mobx';

import {Appraisal} from '../../../../models/appraisal';
import {ConceptReportProvider} from '../../../../business/concept_report_provider';
import {Presenter} from '../../../../../support/presenter/presenter';
import {ReportSelectorType} from '../../../../enum/report_selector_type';
import {RenderingContextType} from '../../../../enum/rendering_context_type';

export class ReportRendererPresenter implements Presenter {
    @observable public initialConceptReport: string | null = null;
    @observable public hasError = false;
    @observable public lastFocusedReference: ReportSelectorType | null = null;
    @observable public htmlIFrameElement: HTMLIFrameElement | null = null;

    constructor(
        private appraisal: Appraisal,
        private renderingContext: RenderingContextType,
        private conceptReportProvider: ConceptReportProvider
    ) {
        makeObservable(this);
    }

    public mount() {
        runInAction(async () => {
            try {
                this.initialConceptReport = await this.conceptReportProvider.getHtmlConceptReport(this.appraisal.id);
            } catch (error) {
                this.hasError = true;
            }
        });
    }

    public unmount() {
        //
    }

    @action
    public onIframeRefupdate(iframe: HTMLIFrameElement) {
        if (this.htmlIFrameElement !== iframe) {
            this.htmlIFrameElement = iframe;
            this.htmlIFrameElement.contentDocument?.body.classList.add('concept-report-preview');
        }
    }
}
