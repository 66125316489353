import * as React from 'react';

interface OwnProps {
    src: string;
    showToolbar?: boolean;
    page?: number;
}

export const PdfViewer: React.FC<OwnProps> = ({src, showToolbar = false, page}) => {
    const query = React.useMemo(() => {
        const params = new URLSearchParams();

        if (!showToolbar) {
            params.set('toolbar', '0');
            params.set('navpanes', '0');
            params.set('scrollbar', '0');
            params.set('statusbar', '0');
        }

        if (page !== undefined) {
            params.set('page', page.toString());
        }

        params.set('view', 'FitH');

        return params.toString();
    }, [showToolbar, page]);

    return <iframe style={{width: '100%', height: '100%'}} key={query} src={src + '#' + query} />;
};
